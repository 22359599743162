.container {
  height: auto;
  width: 100vw;
}

body {
  top: 0;
  left: 0;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Decimal 2 A", "Decimal 2 B";
  font-style: normal;
  font-weight: 100;
}

a {
  text-decoration: none;
}

div {
  width: 100vw;
}